
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const progressColor = "#0b91a0"
      const emptyColor = "#6E84A3"
      const slf = props.data.field.options.selectLabelFunction
      const red = props.data.field.options.reduceFunction
      const reducer = red ? red : (i => i)
      if (slf) {slf(props.data.item)}

      return {
        progressColor,
        emptyColor,
        reducer
      }
    }
  })
